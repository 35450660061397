.grey-btn {
  background-color: var(--grey-bg) !important;
  color: white !important;
}

.primary-btn {
  background-color: var(--slingBlue) !important;
  color: white !important;
}

.MuiBadge-badge {
  background-color: var(--slingRed) !important;
}

.MuiFab-root {
  background-color: var(--background) !important;
  box-shadow: none !important;
}

.MuiFab-root.active {
  background-color: var(--slingBlue) !important;
}

.login .MuiTextField-root {
  border-radius: 10px !important;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
}

.login .MuiOutlinedInput-notchedOutline {
  border-color: var(--slingBlue) !important;
  border-radius: 12px !important;
  border-width: 2px !important;
}