.sidebar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  color: white;
  padding: 20px;
}

.sidebar-logo {
  width: 160px;
  margin-top: 20px;
  margin-left: 20px;
}

.sidebar .ps-menu-button {
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-radius: 8px;
  margin-bottom: 10px;
}

.sidebar .ps-menu-button.ps-active {
  background-color: var(--grey-bg);
  border-radius: 8px;
}

.sidebar .ps-menu-button:hover {
  background-color: var(--grey-bg) !important;
}