@import url('./Colours.css');

.App {
  color: var(--primary);
  text-align: left;
  background: #F6F7FB;
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
}

.only-mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .only-mobile {
    display: block;
  }
}

.only-desktop {
  display: block;
}

@media screen and (max-width: 767px) {
  .only-desktop {
    display: none;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

Link {
  text-decoration: none;
}

Link:hover {
  text-decoration: none;
}

.text-red {
  color: var(--slingRed);
}

.text-blue {
  color: var(--slingBlue);
}

.text-green {
  color: var(--green);
}

.text-yellow {
  color: var(--yellow);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.white-box {
  background-color: var(--foreground);
  border-radius: 20px;
  padding: 15px;
}

.data-box {
  background-color: var(--grey-bg);
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

th {
  color: #B5B7C0 !important;
}

td {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.modal-card {
  flex: 1;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
}

.modal-card.green-bg {
  background-color: var(--green);
  color: white;
}

.modal-card.red-bg {
  background-color: var(--slingRed);
  color: white;
}

.cursor-pointer {
  cursor: pointer;
}